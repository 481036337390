import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"

import {
  PostHero,
  PostContent,
  PostBanerHero,
  PostBanerContent,
  PostNav,
  PostOther,
  PostDoubleContent,
} from "page_components/news-knowledge"

const Post = ({ pageContext, data }) => {
  const fullSchema = JSON.parse(pageContext?.data?.data?.seo?.schema?.raw)

  return (
    <Layout
      seo={{
        title: pageContext?.data?.data?.seo.title
          ? pageContext?.data?.data?.seo.title
          : pageContext?.data?.title,
        description: pageContext?.data?.data?.seo.metaDesc
          ? pageContext?.data?.data?.seo.metaDesc
          : "Inwestycje w apartamenty premium. Zarabiaj inwestując w apartamenty inwestycyjne, hotelowe, komercyjne.",
      }}
    >
      <Breadcrumbs
        title={pageContext?.data?.title}
        parent={{
          title: "Aktualności/Baza wiedzy",
          path: "/aktualnosci/",
        }}
      />
      {pageContext?.data?.data?.acfPost?.postVariant ===
      "withInvestmentBaner" ? (
        <>
          <PostBanerHero
            title={pageContext?.data?.title}
            data={pageContext?.data?.data}
          />
          <PostBanerContent data={pageContext?.data?.data} />
        </>
      ) : pageContext?.data?.data?.acfPost?.postVariant ===
        "doubleInvestmentInfo" ? (
        <>
          <PostHero data={pageContext?.data?.data} />
          <PostDoubleContent data={pageContext?.data?.data} />
        </>
      ) : (
        <>
          <PostHero data={pageContext?.data?.data} />
          <PostContent data={pageContext?.data?.data} />
        </>
      )}
      <PostNav data={pageContext?.data} />
      <PostOther data={data} />

      {fullSchema && (
        <script type="application/ld+json">
          {JSON.stringify({ ...fullSchema }, null, 2)}
        </script>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    allWpPost(
      limit: 10
      sort: { fields: date, order: DESC }
      filter: { id: { ne: $id } }
    ) {
      nodes {
        id
        title
        uri
        excerpt
        date
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Post
